.homeIntroCont{
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom:75px;
}
.introImgCont{
    position: relative;
}
@keyframes shapeMover {
    0%, 100% {
      -webkit-transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
      transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }
    50% {
      -webkit-transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
      transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
    }
  }
.introImgCont::after{
    content: '';
    width: 205px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: url(../../assets/screens/homeImg1Shape.png) no-repeat center center transparent;
    opacity: 0.2;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.introImgCont .image{
    position: relative;
    z-index: 2;
}
.image img{
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.introContentCont{
    padding: 0px 15px 45px 85px;
    width: 50%;
}
.introSubTitle{
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 30px;
    color: #696969;
}
.introTitle{
    font-size: 50px;
    color: #00A4B7;
    margin-bottom: 30px;
    font-weight: bold;
}
.introTitleBelowText{
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 20px;
}
.introFeaturesText{
    font-size: 17px;
    font-weight: 500;
}
.introDesc{
    margin-top: 28px;
    color: #7A838B;
    font-size: 20px;
}

.bgCont{
    margin-bottom:75px;
    background-attachment: fixed;
    background-image: url(../../assets/screens/homeBgImg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; 
   }

.bgContoverlay{
    background-color:#00294B;
    opacity: 0.82;
    transition:  0.3s, border-radius 0.3s, opacity 0.3s;
    width: 100%;
    height: 100%;
    padding: 110px 0px 100px 0px;
}
.bgTextCont{
    display: flex;
    justify-content: center;
}
.bgText{
    color: #FFFFFF;
    font-size: 60px;
    font-weight: 700;
    line-height: 68px;
    margin-bottom: 0px;
    max-width: 900px;
    text-align: center;
}
.bgButtonCont{
    display: flex;
    justify-content: center;
}
.bgButton{
    padding: 20px;
    background-color: #00A4B7;
    text-align: center;
    border: none;
    color: white;
    margin-top: 20px;
    opacity: 1;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
}

.homeAboutCompanyCont{
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-bottom:75px;
}
.homeAboutCompanyUpCont{
    display: flex;
}
.homeAboutCompanyUpLeftCont{
    width: 50%;
    font-weight: 700;
}
.homeAboutCompanyUpRightCont{
    width: 50%;
    margin-top: 28px;
    color: #7A838B;
    font-size: 20px;
    padding: 0px 15px 0px 85px;
}
.homeAboutCompanyDownCont{
    display: flex;
    margin-top: 20px;
    align-items: center;
}
.homeAboutCompanyImg{
    width: 30%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
}
.homeAboutCompanyMiddleCont{
    width:21.415%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 15px 0px 15px;
    justify-content: center;
}
.progressBarText{
    color: black;
    font-size: 18px;
    line-height: 26px;
    margin-top: 6px;
    font-weight: 700;
    text-align: center;
}
.progressBarTextUp{
    color: black;
    font-size: 18px;
    line-height: 26px;
    margin-top: 6px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #7A838B;
}
.progressBarDownCont{
    margin-top: 20px;
}
.homeFAQAccordian{
   width: 47.917%; 
}
.accordion-question{
    background-color: #EDF3F6;
    border: none;
    width: 100%;
    padding: 20px;
    border: 1px solid #00A4B7;
    border-top: none;
    margin-bottom: 20px;
    color: #00A4B7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
}
.accordion-item:first-child{
    border-top: 1px solid #00A4B7;
    border-radius: 8px;
}
.accordion-answer {
    display: none;
    color: #7A838B;
    font-size: 20px;
    padding: 0px 15px 0px 85px;
    transition: all 0.3s ease-in-out;
  }
  .accordion-item.active{
    border: 2px solid #C9D5DB;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .accordion-item.active .accordion-question{
    border: none;
    border-bottom: 1px solid #00A4B7;
  }
  .accordion-item.active .accordion-answer {
    display: block;
  }
  .homeSlider{
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-bottom:75px;
  }
  .slider{
    padding: 20px;
    width: 100%;
    padding-bottom:75px;
  }
  .sliderButtons{
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background-color: #00A4B7; */
    margin-bottom: 10px;
    background-color: rgba(0,0,0,0.5);
  }
  .sliderButtons:disabled{
    opacity: 0.5;
  }
  .homeSliderContent{
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    cursor: pointer;
    margin-top: 10px;
  }

  .homeSliderContent:hover{
    text-decoration: underline;
  }
  .homeSliderDate{
    font-size: 11px;
  }
  .carousel-arrows {
    display: flex;
    justify-content: space-between;
    margin-top: 10px; /* Adjust as needed */
  }
  
  .carousel-arrows button {
    background: none; /* Remove default button styles */
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .carousel-arrows button:hover {
    opacity: 0.7; /* Add hover effect */
  }

  .homeBlock5{
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-bottom:75px;
    display: flex;
  }
  .homeBlock5Left{
    width: 50%;
  }
  .homeBlock5Right{
    width:50%;
    padding: 0px 15px 45px 85px;
  }
  .homeGridTitle{
    color: #fff;
    font-size: 30px;
    width:50%;
    border:2px solid white;
    color:white;
    background:#00A4B7;
    text-align: left;
    font-weight: bold;
    padding-left: 30px;
  }
  .homeJournalsMenu div{
    font-weight: bolder;
    cursor: pointer;
  }
  .homeJournalsMenu .active{
    text-decoration: underline;
  }
  .homeJournalsMenu div:hover{
      text-decoration: underline;
  }
  .homejournalscontainer {
    clear: both;
    font-size: 1em;
    line-height: 1.6em;
    padding-top: 40px;
    padding-right: 0;
    padding-bottom: 40px;
    padding-left: 0;
  }
  .homejournalsCardWrapper{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 32px;
    row-gap: 32px;
    margin-bottom: 56px
  }
  
  .homejournalscardEditor{
    display: flex;
    background-color: white;
    padding: 0px;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    filter: brightness(98%);
  }
  .homejournalscardEditor:hover{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
    transition: box-shadow .48s cubic-bezier(.37,.74,.15,1);
    transition-behavior: normal;
    transition-duration: 0.48s;
    transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1);
    transition-delay: 0s;
  }
  
  .homejournalsName{
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding: 8px;
    background: white;
    text-align: left;
  }
  
  .homejournalsSingCit{
    background: white;
      backdrop-filter: blur(30px);
      padding: 0px;
      color: black;
      text-align: left;
  }
  
  .homejournalsSingCitNumber{
    font-weight: bold;
    color: black;
    margin-left: 3px;
    padding-right: 10px;
  }
  
  .homejournalsSingCitText{
    color: black;
    font-size: 14px;
    margin-left: 5px;
    padding-left: 10px;
  }
  
  .homejournalsRateCont{
     background-color: #00A4B7;
  padding: 5px;
  position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .homejournalsRate{
    color: white;
    font-weight: bold;
    float: right;
    margin-right: 3px;
  }
  @media  screen and (max-width: 1024px){
  .homejournalsCardWrapper {
     grid-template-columns: repeat(2,1fr);
     margin-bottom: 48px;
     row-gap: 24px;
     column-gap: 16px;
  }
  }
  
  @media  screen and (max-width: 768px){
    .homejournalsCardWrapper {
        grid-template-columns: repeat(2,1fr);
    }}
    
    @media  screen and (max-width: 563px){
    .homejournalsCardWrapper {
        grid-template-columns: repeat(1,1fr);
    }
  }
  
  @media screen and (min-width: 361px){
    .homejournalscontainer{
        max-width: 95%;
        margin: 0 auto;
    }}
    
    
    @media screen and (min-width: 1140px){
    .homejournalscontainer
    {
        max-width: 95%;
        margin: 0 auto;
    }
    }
@media (max-width:1024px) {
.homeIntroCont{
    display: block;
}
.introContentCont{
    width: 100%;
    margin-top: 30px;
    padding: 20px;
}
.homeBlock5{
    display: block;
}
.homeBlock5Left{
    width: 100%;
  }
  .homeBlock5Right{
    width: 100%;
    margin-top: 30px;
    padding: 20px;
  }
}
@media (max-width:800px) {
    .homeAboutCompanyUpCont{
        display: block;
    }
    .homeBlock5{
        display: block;
    }
    .homeAboutCompanyUpLeftCont{
        width: 100%;
    }
    .homeAboutCompanyUpRightCont{
        width: 100%;
    }
    .homeAboutCompanyDownCont{
        display: block;
    }
    .homeAboutCompanyImg{
        height: 250px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .homeAboutCompanyMiddleCont{
        width: 100%;
        margin-top: 20px;
        justify-content: space-around;
    }
    .progressBarTextUp{
        border: none;
    }
    .progressBarDownCont{
        margin-top: 0px;
    }
    .homeFAQAccordian{
        width: 100%;
        margin-top:20px;
    }
    .bgText{
        font-size: 26px;
        line-height: 40px;
    }
}
@media (max-width:460px) {
    .introTitle{
        font-size: 32px;
    }
}

@media (max-width:400px) {
    .introContentCont{
        padding: 0px;
    }
    .homeAboutCompanyUpRightCont{
        padding: 0px;
    }
    .accordion-answer{
        padding: 10px;
    }
}

