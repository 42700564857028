.ContentCard{
     box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
     background: white;
     border-radius: .375rem;
     padding: 20px;
}
.CardJournalName a{
    font-weight: 600;
    font-size: 20px;
    line-height: 1.45;
    text-decoration: none;
    color: black;
}

.CardJournalName a:hover{
    color: #00A4B7;
} 

.CardJournalDate{
 color: #5C5956;
 text-align: center;
}
.ContentCardRight{
    border-right: 1px solid rgba(92,89,86,0.25);
    border-bottom: none;
    padding: 10px;
}
.downloadText{
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #305F8B;
    padding: 10px;
    color: #00A4B7;
    text-align: center;
}
.downloadText:hover{
    text-decoration: underline;
}
@media (max-width: 1093px){
    .hideMedium{
        display: none;
    }
}
@media (max-width: 768px){
    .ContentCardRight{
        border-right: none;
        border-bottom: 1px solid rgba(92,89,86,0.25);
    }
    .hideMedium{
        display: contents;
    }
    .ContentCardLeft{
        margin-top: 20px;
    }
    
}

@media (max-width: 375px){
    .hideMedium{
        display: none;
    }
}
