.articleHeading{
    background-color: rgba(31,83,140,1);
    border-bottom: 1px solid #E0E0E0;
    color: white;
    padding: 20px;
    font-size: 22px;
}

.tabContentHeading{
    font-weight: 500;
    font-size: 22px;
    border-bottom: 1px solid #E7EAED;
    padding-bottom: 10px;
}