.journalTopCont{
    background-image: url(../../assets/screens/WebsiteJpg_XL-FAMS_Main\ Visual_Cyan_Website.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 341px;
}
.section {
  z-index: 1;
  padding: 20px;
  scroll-margin-top: 50px;
}
table{
  border: none;
}
tbody tr:first-child{
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.showJump{
  display: none;
}
.showPagesMenuBars{
  display: none;
  cursor: pointer;
  color: white;
  width: 10%;
  background-color: rgba(31,83,140,1);
  height:4rem;
  padding: 20px 10px 5px 20px;
}

.journalHeading{
    color:#545454;
    font-weight:500;
    font-size: 1.125rem;
}
.journalMenuListItems{
    text-align: left;
    display: block;
    padding:10px 30px;
    text-decoration: none;
    color:#545454;
    font-weight: 500;
    background: transparent;
    border:none;
  }
.journalMenuListItems:hover{
    color: #31c7d1;
    text-decoration: none;
}
.dropDownStyle{
    position:absolute;
    top:48px;
    left:0px;
    z-index:8;
    background-color: white;
    padding: 20px;
    width: 100%;
}

.dropDownStyle a{
     color:#6B6B6B;
     text-decoration: none;
}

.dropDownStyle a:hover{
    color:black;
}

.smallJourMenu{
    position:absolute;
    top:-4rem;
    background: white;
    color: black;
    z-index: 5;
}

.smallDropDownStyle{
    background-color: white;
    border-radius: 10px;
}

.smallDropDownStyle a{
    color:#6B6B6B;
    text-decoration: none;
}

.smallDropDownStyle a:hover{
   color:black;
}

.imgHeading{
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 500;
    color: white;
    padding-top: 150px;
    padding-left: 100px;
}

.citationsCont{
    position: absolute;
    bottom: 0;
    right: 0;
}

.singCit{
    width: 200px;
    padding: 15px;
    color: white;
    cursor: pointer;
}

.singCitNumb{
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
}

.showSmallSubmit{
    display: flex;
}

.showBigSubmit{
    display: none;
}

.slide{
    padding: 40px;
}

.slideHeading{
    width:50%;
    text-align:center;
    font-weight: 500;
}

.slideDesc{
    width:50%
}

.slideLink{
    color:#31c7d1;
    margin-top: 20px;
}

.slideLink a{
    color:#31c7d1;
    margin-top: 20px;
}

.carousel-indicators {
    bottom: -13px;
    z-index: 0;
  }
  .carousel-indicators button{
    background-color: black!important;
  }
  .carousel-indicators .active {
    background-color: #337ab7!important; 
  }
  
  .articlesCont{
    background-color: #EBEBEB;
    padding: 40px;
  }
  .ArticleCardWrapper{
    display: grid;
    column-gap: 32px;
    row-gap: 32px;
    margin-bottom: 56px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    overflow: hidden;
    justify-content: center;
    padding: 10px 100px;
  }
  .ArticleCardEditor{
    background: white;
    margin-right: 32px;
    flex: 0 0 calc(20.83333vw - 38.66667px);
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
  }
  .ArticleCardEditor:hover{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    transition: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) .48s cubic-bezier(.37,.74,.15,1);
    transition-duration: 0.48s;
    transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1);
    transition-delay: 0s;
}

  .singleArticle{
    background: white;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px;
  }

  .singleArticleType{
    color: black;
    font-size: 11px;
    padding-top: 10px;
    font-size: .625rem;
    line-height: .875rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .singleArticleDate{
    color: #666;
    font-size: 9px;
  }

  .singleArticleName{
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .singleArticleAuthors{
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
  }

  @media (max-width: 1280px) {
    .singCit{
        padding: 10px;
        width: 152px
    }
    .imgHeading{
        font-size: 2rem;
    line-height: 2.75rem;
    }
    .ArticleCardWrapper{
      padding: 10px 30px;
    }
  }

  @media  screen and (max-width: 1024px){
 .ArticleCardWrapper {
     grid-template-columns: repeat(2,1fr);
 }
 .ArticleCardEditor{
  margin-right: 0px;
}
}
  @media (max-width: 992px) {
    .showPagesMenuBars{
      display: contents;
    }
    .journalTopCont{
        height: 192px;
    }
    .showJump{
      display: block;
    }
    .imgHeading{
        font-size: 1.50rem;
    line-height: 2.4375rem;
    padding-top: 30px;
    }
    .singCit{
        padding: 5px;
        width: 125px;
    }
    .slideHeading{
        width:100%;
    }
    
    .slideDesc{
        width:100%;
        text-align: center;
    }

    
  }

  @media  screen and (max-width: 768px){
    .ArticleCardWrapper {
        grid-template-columns: repeat(2,1fr);
        padding: 10px;
    }
    .ArticleCardEditor{
      margin-right: 0px;
    }
  }

 
  @media (max-width: 700px) {
    .articlesCont{
        padding: 20px;
    }
  }
  
  @media (max-width: 600px) {
    .imgHeading{
    font-size: 22px;
    padding-top: 30px;
    text-align: center;
    padding-left: 0px;
    }
}


  @media (max-width: 563px) {
    .ArticleCardWrapper {
        grid-template-columns: repeat(1,1fr);
    }
    .imgHeading{
    font-size: 20px;
    padding-top: 30px;
    text-align: center;
    padding-left: 0px;
    }
    .citationsCont{
        width:100%;
    }
    .jusCitations{
        width: 100%;
    }
    .singCit{
        width: 33.3%;
    }
    .showSmallSubmit{
        display: none;
    }
    .showBigSubmit{
        display: flex;
        width: 100%;
        padding: 10px;
    }
  }

  @media (max-width: 400px) {
    .journalTopCont{
        height: 210px;
    }
    .imgHeading{
        font-size:18px;
        line-height: 20px;
        padding-top: 58px;
    }
    .singCitNumb{
        font-size: 16px;
    }
    .singCitName{
        font-size: 14px;
    }
    .slide{
        padding: 10px;
    }
  }
