
.common-headerimage{
    margin-top: -15px;
    background-image: url(https://static.hindawi.com/styles/hero_image_medium/s3/2019-12/contact-us_hero_tab.jpg?itok=uPYyjMWe);
    min-height: 320px;
    position: relative;
    width: 100%;
    margin-top: -80px;
    background-color: rgb(255, 255, 255);
    background-image: url(https://static.hindawi.com/styles/hero_image_small/s3/2019-12/otherPages_mob_2.jpg?itok=jw86uAME);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
}
.contactApp {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* height: 100vh; */
    padding: 20px;
    box-sizing: border-box;
   }
   
   .email-us, .office {
    flex: 1;
 
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-left: 10%;

   }
   
   @media (min-width: 768px) {
    .contactApp {
       flex-direction: row;
    }
   }

   .aboutUs{
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    background-image: url(../../assets/screens/contact-us_hero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 375px;
}

.textInImg{
    width: 60%;
    padding: 100px 0px 0px 137px;
    color: white;
   }
   .heading{
    margin-right: 0px;
    color: rgb(255, 255, 255);
    line-height: 30px;
    font-size: 40px;
   }

   .description{
    font-size: 18px;
    margin-top: 23px;
    margin-bottom: 17px;
    margin-right: 0px;
    color: rgb(255, 255, 255);
   }
 .mapContainer{
    width: 100%;
    padding: 50px 0px 0px 0px;
 }
 .mapCont div iframe{
   display: flex;
   margin-right: auto;
   margin-left: auto;
   position: relative;
   width: 90%;
   z-index: -1;
 }
 
 .contactInfo{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
 }

 .contactInfoLeft{
    width: 60%;
 }
 .contactUsCardCont{
    border-top: 5px solid #00A4B7;
    background-color: white;
    padding: 20px;
    margin-top: -40px;
    margin-right: 25px;
    width: 30%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
 }
 .contactFormInputCont{
    width: 49%;
 }

 .contactFormInput{
    background-color: #EDF3F6;
    padding: 20px;
    border: none;
    height: 60px;
    width: 100%;
    border-radius: 8px;
 }
 .contactFormInputButton{
   background-color: #305F8B;
   border: none;
   color: white;
   padding: 20px;
   border-radius: 8px;
 }
 .contactFormInputCont1{
    width:100%;
 }

 .contactUsSocialIcons .twitter{
    background-color: #00A4B7;
    background-image: url(../../assets/screens/twitterWhite.png);
    background-position: center center;
    background-repeat: no-repeat;
    height:50px;
    width:50px;
    margin-right: 5px;
 }
 .contactUsSocialIcons .twitter:hover{
    background-color: #FFFFFF;
    background-image: url(../../assets/screens/twitterBlue.png);

 }

 .contactUsSocialIcons .facebook{
    background-color: #00A4B7;
    background-image: url(../../assets/screens/facebookWhite.png);
    background-position: center center;
    background-repeat: no-repeat;
    height:50px;
    width:50px;
    margin-right: 5px;
 }
 .contactUsSocialIcons .facebook:hover{
    background-color: #FFFFFF;
    background-image: url(../../assets/screens/facebookBlue.png);
 }
 .contactUsSocialIcons .insta{
    background-color: #00A4B7;
    background-image: url(../../assets/screens/instaWhite.png);
    background-position: center center;
    background-repeat: no-repeat;
    height:50px;
    width:50px;
    margin-right: 5px;
 }
 .contactUsSocialIcons .insta:hover{
    background-color: #FFFFFF;
    background-image: url(../../assets/screens/instaBlue.png);
 
 }

 .contactUsSocialIcons .linkedin{
    background-color: #00A4B7;
    background-image: url(../../assets/screens/linkedinWhite.png);
    background-position: center center;
    background-repeat: no-repeat;
    height:50px;
    width:50px;
    margin-right: 5px;
 }
 .contactUsSocialIcons .linkedin:hover{
    background-color: #FFFFFF;
    background-image: url(../../assets/screens/linkedinBlue.png);
 
 }
 

 .msgCont{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
 }

@media (min-width: 768px) and (max-width: 992px){
    .mapCont div iframe{
        width: 98%; 
    }
    .contactInfo{
        width: 98%; 
    }
    .contactInfoLeft{
        width: 98%; 
    }
    .msgCont{
        width: 98%;
    }
    .contactUsCardCont{
        margin-top: 20px;
        z-index: 500;
        margin-right: 0px;
        width: 98%;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    }
    .contactFormInputCont{
        width: 100%;
     }
    
     .contactFormInput{
        margin-top: 25px;
     }
     
    .aboutUs {
        background-image: url(../../assets/screens/contact-us_hero.jpg);
        height: 375px;
        overflow: hidden;
    }
    .textInImg{
        width: 60%;
        padding: 50px 40px 0px;
        max-width: 670px;
    }
    .heading{
        line-height: 50px;
    margin-right: 0px;
    }
    .description{
        margin-top: 22px;
    margin-bottom: 26px;
    width: 100%;

    }
}

@media (max-width: 768px){
    .mapCont div iframe{
        width: 98%; 
    }
    .contactInfo{
        width: 98%; 
    }
    .contactInfoLeft{
        width: 98%; 
    }
    .msgCont{
        width: 98%;
    }
    .contactUsCardCont{
        margin-top: 20px;
        z-index: 500;
        margin-right: 0px;
        width: 98%;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);

     }
     .contactFormInputCont{
        width: 100%;
     }
    
     .contactFormInput{
        margin-top: 25px;
     }
    .aboutUs {
        background-image: url(../../assets/screens/contact-us_hero.jpg);
        height: 320px;
    }
    .textInImg{
        width: 100%;
    padding: 50px 40px 0px;
}
.heading{
    margin-right: 0px;
    line-height: 40px;
    font-size: 30px;
}
.description{
    margin-top: 23px;
    margin-bottom: 23px;
    margin-right: 0px;
}
    }




