
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.headerStyle {
  z-index: 5;
  position: sticky;
  top: 0px;
  height: 4rem;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid #E0E0E0;
}

.headerStyle.down {
  top: -4rem;
}

.hide{
  top: -4rem;
}

.show{
  top:0px;
}