
.header {
   
    display: flex;
    justify-content: space-between;
    align-items: center;
   
  }
  .showBars{
    display: none;
  }
  .nav-list {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
  }
  
  .nav-list li {
    margin-right: 20px;
  }
  
  .nav-link {
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  
  .nav-link.active {
    /* color: #305F8B; */
    font-weight: bold;
    border-bottom: 3px solid #00A4B7;
  }
  .showPagesMenuBars{
    display: none;
    cursor: pointer;
    color: white;
    width: 10%;
    background-color: rgba(31,83,140,1);
    height:4rem;
    padding: 20px 10px 5px 20px;
  }
  @media (max-width: 992px) {
    .showPagesMenuBars{
      display: contents;
    }
    .headerDiv {
      position: sticky;
    }
  
    .header {
      display: none;
    }
    .showBars{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
  }
  .openList {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 100;
    list-style: none;
    border-radius: 10px;
  }
  
  .openList.open {
    display: block;
  }
  
  .openList li {
    margin: 5px 0;
  }
  
  .openList a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #59595B;
    font-weight: 500;
  }
  
  .openList a.active {
    color: #49A6CF;
    font-weight: bold;
    border: none;
  }
  