

.navigation {
    padding: 20px;
    width: 20%;
     }

     .pagenavigation{
        padding: 20px;
        width: 20%;
        height: 550px;
        position: fixed;
        right: 0px;
      }
      .paraSide{
        width:80%;
        padding: 30px 50px;
      }
  
      .articleMenu{
        border-top: none;
        border-left: none;
  }
  .articleSmallMenu{
    border: none;
  }
  .articleMenu a {
    display: block;
    padding: 5px 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: #4a4a4a;
    font-weight: 500;
    padding-left: 20px;
    border-left: 4px solid #305F8B;
  }
  .articleSmallMenu a {
    display: block;
    padding: 5px 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  .articleMenu a div{
    padding: 5px;
  }
  .articleSmallMenu a div{
    padding: 5px;
  }
  .articleMenu a:hover {
    /* background-color:#d8d2d2; */
    color: #00A4B7;
 }
 .articleSmallMenu a:hover {
  /* background-color:#d8d2d2; */
  color: #00A4B7;
}


.articleContents:hover{
    color: #00A4B7;
   }
   .articleContents1:hover{
    color: #00A4B7;
   }
  
   .pagenavigation div > a.active {
    background-color: #000;
    color: #fff;
    text-align: center;
  }
  .section {
    z-index: 1;
    padding: 20px;
    scroll-margin-top: 50px;
  }
  .paraSideshow{
    text-align: justify;
  }
  .paraSidehide{
    text-align: justify;
  }
  .paraSideshow h2{
    padding-top: 23px;
    scroll-margin-top: 50px;
  }
  .paraSidehide h2{
    padding-top: 23px;
    scroll-margin-top: 50px;
  }
  table{
    border: none;
  }
  tbody tr:first-child{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  .showJump{
    display: none;
  }
  .showPagesMenuBars{
    display: none;
    cursor: pointer;
    color: white;
    width: 10%;
    background-color: rgba(31,83,140,1);
    height:4rem;
    padding: 20px 10px 5px 20px;
  }
  .pagesParaSide{
    width:80%;
    padding: 0px 30px;
  }

  @media (max-width: 992px) {
    
  .showPagesMenuBars{
    display: contents;
  }
  .showJump{
    display: block;
  }
  .pagenavigation{
    display: none;
  }
  .pagesParaSide{
    width:100%
  }
  }