.blogContainer{
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
}
.blogsSide{
    width:78%;
}
.singleBlog{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.blogImgCont{
    margin-left: 25px;
    position: relative;
    margin-right: 25px;
}
.blogImg{
    height: 200px;
    border-radius: 8px;
    z-index: 1;
    background-color: #383838;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(100%);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}
.blogImg:hover{
    filter: brightness(90%);
    transform: translate3d(0px, 0px, 0px) scale3d(1.06, 1.06, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.blogTag{
  position: absolute;
  top: 10px;
  left: 10px;
    height: 24px;
    color: #fff;
    background-color: rgba(21, 21, 21, .7);
    border-radius: 12px;
    padding: 3px 12px;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
}
.blogName{
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}
.blogName:hover{
    text-decoration: underline;
}
.blogDesc{
    color: #777;
    margin-bottom: 12px
}
.blogDate{
    color: #777;
    font-size: 11px;
    line-height: 16px;
}
.headerBorder{
    border-bottom: 1px solid #151515;  
    margin-bottom: 20px;
}
.filtersButton{
    display: none;
}
.tagFiltersCont{
    position: fixed;
    top: 6rem;
    right: 30px;
    width: 20%;
}

.tagStyle{
    border: 1px solid #777;
    padding: 3px;
    border-radius: 5px;
    color: black;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    background:none;
}
.tagactive{
    background-color: #00A4B7;
    color: white;
}
.tagStyle:hover{
 text-decoration: none;
 background-color: #00A4B7;
 color: white;
 cursor: pointer;
}
.pageButtons{
    background-color: #00A4B7;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 8px;
}
@media (max-width:991px) {
    .blogContainer{
        display: block;
    }
    .singleBlog{
        align-items: center;
    }
    .blogImg{
        width: 34vw;
        height: 26vw;
    }
    .tagFiltersCont{
        display: none;
    }
    .blogsSide{
        width: 100%;
    }
    .filtersButton{
        display: block;
        position: fixed;
        top: 4rem;
        right: 4px;
        background-color: #305F8B;
        padding: 5px;
        z-index: 5;
    }
}

@media (max-width:479px) {
    .singleBlog{
       display: block;
    }
    .blogImgCont{
        margin:0px;
    }
    .blogImg{
        width: 100%;
        height: 64vw;
        display: block;
    }
}