.singleBlogImg{
    filter: brightness(100%);
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-left: 15%;
    padding-top: 20px;
    color: white;
}
.singleBlogTag{
    background-color: #00A4B7;
    width: min-content;
    border-radius: 12px;
    padding: 3px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}
.singleBlogName{
    text-transform: capitalize;
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 44px;
    font-weight: 700;
    line-height: 60px;
}
.singleBlogDate{
    font-size: 14px;
    line-height: 16px;
}
.singleBlogContainer{
    padding: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 30px;
}
.singleBlogdescPart{
    width: 70%;
    margin-right: 30px;
}
.blongsSide{
    width: 30%;
}
.singleBlogdescPart img{
    width: -webkit-fill-available;
}
@media (max-width:991px) {

    .singleBlogdescPart{
        width: 100%;
        margin-right: 0px;
    }
    .blongsSide{
        width: 100%;
    }
}

@media (max-width:479px) {
    .singleBlogName{
        font-size: 32px;
        line-height: 30px;
    }
}