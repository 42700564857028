.journalsMenu div{
  font-weight: bolder;
  cursor: pointer;
}
.journalsMenu .active{
  text-decoration: underline;
}
.journalsMenu div:hover{
    text-decoration: underline;
}
.journalscontainer {
  clear: both;
  font-size: 1em;
  line-height: 1.6em;
  padding-top: 40px;
  padding-right: 0;
  padding-bottom: 40px;
  padding-left: 0;
}
.journalsCardWrapper{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 32px;
  row-gap: 32px;
  margin-bottom: 56px
}

.journalscardEditor{
  display: flex;
  background-color: white;
  padding: 0px;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  filter: brightness(98%);
}
.journalscardEditor:hover{
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
  transition: box-shadow .48s cubic-bezier(.37,.74,.15,1);
  transition-behavior: normal;
  transition-duration: 0.48s;
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1);
  transition-delay: 0s;
}

.journalsName{
  color: black;
  font-size: 22px;
  font-weight: bold;
  padding: 8px;
  background: white;
  text-align: left;
}

.journalsSingCit{
  background: white;
    backdrop-filter: blur(30px);
    padding: 0px;
    color: black;
    text-align: left;
}

.journalsSingCitNumber{
  font-weight: bold;
  color: black;
  margin-left: 3px;
  padding-right: 10px;
}

.journalsSingCitText{
  color: black;
  font-size: 14px;
  margin-left: 5px;
  padding-left: 10px;
}

.journalsRateCont{
  background-color: #00A4B7;
  padding: 5px;
  position: absolute;
    bottom: 0px;
    width: 100%;
}
.journalsRate{
  color: white;
  font-weight: bold;
  float: right;
  margin-right: 3px;
}
@media  screen and (max-width: 1024px){
.journalsCardWrapper {
   grid-template-columns: repeat(2,1fr);
   margin-bottom: 48px;
   row-gap: 24px;
   column-gap: 16px;
}
}

@media  screen and (max-width: 768px){
  .journalsCardWrapper {
      grid-template-columns: repeat(2,1fr);
  }}
  
  @media  screen and (max-width: 563px){
  .journalsCardWrapper {
      grid-template-columns: repeat(1,1fr);
  }
}

@media screen and (min-width: 361px){
  .journalscontainer{
      max-width: 95%;
      margin: 0 auto;
  }}
  
  
  @media screen and (min-width: 1140px){
  .journalscontainer
  {
      max-width: 95%;
      margin: 0 auto;
  }
  }