.container {
    clear: both;
    font-size: 1em;
    line-height: 1.6em;
    padding-top: 40px;
    padding-right: 0;
    padding-bottom: 40px;
    padding-left: 0;
}

.EditorialHeading {
    color: rgba(31,83,140,1);
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
.cardEditor{
    display: flex;
    background-color: white;
    padding: 15px;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}
.cardEditor:hover{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    transition: box-shadow .48s cubic-bezier(.37,.74,.15,1);
    transition-behavior: normal;
    transition-duration: 0.48s;
    transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1);
    transition-delay: 0s;
}

.cardStyle{
    flex-direction: column;
}

.postImage{
    display: flex;
    justify-content: center;
}
.postImage img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.postDetails{
    font-size: 14px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;
    flex: 1;
    width: 100%;
}

.postName{
    font-weight: 500;
    font-size: 22px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    text-transform: capitalize;
    text-align: center;
}

.postProfession{
    text-align: center;
    color: #6b6b6b;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 300;
    margin-bottom: 8px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.postUni{
    text-align: center;
    color: #6b6b6b;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 300;
}
.postPE{
    font-weight: bold;
}
.cardStyleDown{
    padding: 16px 32px 24px;
    background: #fff;
    color: #6b6b6b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}
.postArticleName{
    font-size: .75rem;
    line-height: 1.0625rem;
    font-weight: 500;
    color: #6b6b6b;
    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.CardWrapper{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 32px;
    row-gap: 32px;
    margin-bottom: 56px
}
@media  screen and (max-width: 1441px){
.postProfession {
    font-size: .75rem;
    line-height: 1.0625rem;
}
.postUni {
    font-size: .75rem;
    line-height: 1.0625rem;
}
.cardStyleDown{
    padding: 16px;
}
.postArticleName{
    font-size: .625rem;
    line-height: .875rem;
}
}
@media  screen and (max-width: 1280px){
    .postImage img{
        width: 48px;
        height: 48px;
    }
    }
@media  screen and (max-width: 1024px){
   .cardStyle{
    flex-direction: row;
    padding: 0 0 16px;
    align-items: flex-start;
    min-height: 0;
    border-bottom: 1px solid #F0F0F0;
}
.cardStyleDown{
    padding-top: 8px;
    height: auto;
}
.postDetails{
    border-bottom: none;
}
.CardWrapper {
    grid-template-columns: repeat(2,1fr);
    margin-bottom: 48px;
    row-gap: 24px;
    column-gap: 16px;
}
.postImage img{
    width: 80px;
    height: 80px;
}
.postName{
    text-align:left;
    padding-left: 25px;
}
.postArticleName{
    text-align: left;
}
.postProfession{
    text-align: left;
    padding-left: 25px;
}
.postUni{
    text-align: left;
    padding-left: 25px;
}
}

@media  screen and (max-width: 768px){
    .postName{
        text-align: left;
    }
    .postProfession{
        text-align: left;
    }
    .postUni{
        text-align: left;
    }
    .postArticleName{
        text-align: left;
    }
}



@media  screen and (max-width: 768px){
.CardWrapper {
    grid-template-columns: repeat(2,1fr);
}}

@media  screen and (max-width: 563px){
.CardWrapper {
    grid-template-columns: repeat(1,1fr);
}
.postImage img{
    width: 48px;
    height: 48px;
}
.cardStyle{
    flex-direction: column;
    align-items: center;
}
.postName{
    text-align: center;
}
.postProfession{
    text-align: center;
}
.postUni{
    text-align: center;
}
.postArticleName{
    text-align: left;
}
}

@media screen and (min-width: 361px){
.container{
    max-width: 95%;
    margin: 0 auto;
}}


@media screen and (min-width: 1140px){
.container
{
    max-width: 1140px;
    margin: 0 auto;
}
}