.aboutUs{
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    background-image: url(../../assets/screens/about-us_hero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 375px;
}
.blockResponsiveWidth{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.textInImg{
    width: 60%;
    padding: 100px 0px 0px 137px;
    color: white;
   }
   .heading{
    margin-right: 0px;
    color: rgb(255, 255, 255);
    line-height: 30px;
    font-size: 40px;
   }

   .description{
    font-size: 18px;
    margin-top: 23px;
    margin-bottom: 17px;
    margin-right: 0px;
    color: rgb(255, 255, 255);
   }
.aboutUsGetToKnowUs{
    display: flex;
}
.aboutUsGetToKnowUsLeft{
    margin-right: 25px;
}
.aboutUsGetToKnowUsRight{
    width: 50%;
}
.aboutUsGetToKnowUsRight img{
    width: -webkit-fill-available;
}
.GetToKnowUsSubTitle{
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 30px;
    color: #696969;
}
.GetToKnowUsTitle{
    font-size: 50px;
    color: #00A4B7;
    margin-bottom: 30px;
    font-weight: bold;
}
.GetToKnowUsDesc{
    margin-top: 28px;
    color: #7A838B;
    font-size: 20px;
}
.borderClass{
  border-right: 1px solid black;
}

.aboutUsImg{
    width: 100%;
    background: #DBE6E6;
    padding: 0px 10px 10px 0px;
}
.aboutUsImgHeading{
    font-size: 1.7em;
    line-height: 1.35;
}
.aboutUsImgPara{
    margin-top: 28px;
    color: #7A838B;
    font-size: 20px;
}
.getStartedCont{
    background-color: #E4EFEF;
}
.getStartedHeading{
    font-weight: 300;
    font-size: 2.2em;
    line-height: 1.2;
    margin-bottom: 0.25em;
}
.getStartedButtton{
    background-color: #00A4B7;
    border: none;
    padding: 6px 22px;
    padding-right: 38px;
    color: white;
    border-radius: 20px;
}
.getStartedButtton:hover{
    color: white;
}
.getStartedButttonIcon{
    background-color: #305F8B;
    color: white;
    width: 14px;
    height: 14px;
    padding: 10px;
    border-radius: 20px;
    position: absolute;
    right:0px;
    top: 0px;
}
.getStartedPara{
    width: 50%;
}

@media screen and (max-width:1025px){
.aboutUsGetToKnowUs{
    display: block;
    margin-bottom: 40px;
}
.aboutUsGetToKnowUsRight{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.aboutUsGetToKnowUsLeft{
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
}
}
@media screen and (max-width:992px){
    .borderClass{
        border: none;
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) and (max-width: 992px){
    .aboutUs {
        background-image: url(../../assets/screens/about-us_hero.jpg);
        height: 375px;
        overflow: hidden;
    }
    
    .textInImg{
        width: 60%;
        padding: 50px 40px 0px;
        max-width: 670px;
    }
    .heading{
        line-height: 50px;
    margin-right: 0px;
    }
    .description{
        margin-top: 22px;
    margin-bottom: 26px;
    width: 100%;

    }
}

@media (max-width: 768px){
    .aboutUsBlock2Heading{
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .getStartedPara{
        width: 100%;
    }
    .aboutUsImg{
        padding: 0px 0px 10px 0px;
    }
    .aboutUs {
        background-image: url(../../assets/screens/about-us_hero.jpg);
        height: 320px;
    }
    .textInImg{
        width: 100%;
    padding: 50px 40px 0px;
}
.heading{
    margin-right: 0px;
    line-height: 40px;
    font-size: 30px;
}
.description{
    margin-top: 23px;
    margin-bottom: 23px;
    margin-right: 0px;
}
    }

    @media (max-width:460px) {
        .GetToKnowUsTitle{
            font-size: 32px;
        }
    }


