
::-webkit-scrollbar {display:none;}

img{
  max-width:100%;
  height:auto;
}
.journalHeaderStyle{
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: rgba(31,83,140,1);
    border-bottom: 1px solid #E0E0E0;
    color: white;
    width: 90%;
  }
  
  .journalHeaderStyle.down {
    top: -4rem;
  }
  
  .journalHeaderhide{
    top: -4rem;
  }
  
  .journalHeadershow{
    top:4rem;
  }

  .jHeading{
    padding: 5px 0 5px 20px;
    font-size: 25px;
  }

  .jHeadingMenu{
    position: sticky;
    background-color: rgb(31,83,140);
    color: white;
    display: flex;
    border-top: 1px solid #fff;
    height: 50px;
    z-index: 2;
  }
  .jHeadingMenu a{
    text-decoration: none;
    color: white;
    padding: 10px;
    text-align: center;
  }

  .jHeadingMenu a:hover{
    text-decoration: none;
    color: black;
    padding: 10px;
    text-align: center;
    background-color: white;
    border:1px solid rgb(31,83,140);
  }
.jHeadingMenuhide{
  top:0px;
}
.jHeadingMenushow{
  top:8rem;
}
  .jMenuItems{
    width: 16.66%;
    border-right: 1px solid #fff;
  }



.jOverviewMenuList{
  display: flex;
    position: absolute;
    left: 0;
    top: 3rem;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    color: black;
    text-align: left;
    padding: 20px;
}
.jOverviewMenuList ul{
  margin:0px 20px;
}
.jOverviewMenuList ul li{
  list-style: none;
  padding: 10px;
  color: black;
}
.jOverviewMenuList ul li:hover{
  text-decoration: underline;
  padding: 10px;
}
  

  

  .openjHeadingMenuList {
    float: right;
    overflow: auto;
    display: none;
    /* 15 */
    width: 45%;
    height: 625px;
    z-index: 10;
    color: white;
    right: 0rem;
    position: sticky;
  }

  .openjHeadingMenuList button{
    background-color: rgb(31,83,140);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .openjHeadingMenuList button:hover{
    background-color: #4778ad;
  }

  .openjHeadingMenuList a{
    background-color: rgb(31,83,140);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .openjHeadingMenuList a:hover{
    background-color: #4778ad;
  }
  

  .openjHeadingMenuListhide{
    top: 3rem;
    height: 100%;
  }
  .openjHeadingMenuListshow{
    top:8rem;
  }
  .openArticleMenuList{
   
    position: fixed;
    display: none;
    width: 50%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    z-index: 10;
    color: white;

  }
  .openArticleMenuList.open{
    display: block;
  }

  .openArticleMenuListhide{
    top: 7rem;
  }
  .openArticleMenuListshow{
    top:9.5rem;
  }
  .openjHeadingMenuList.open {
    display: block;
  }
  
  .openjHeadingMenuList li {
    margin: 5px 0;
  }
  
  .openjHeadingMenuList a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: white;
    font-weight: 500;
    border-top: 1px solid white;
    text-align: left;
    padding:10px 30px;
  }
  
  .openjHeadingMenuList a.active {
    color: #63A431;
    font-weight: bold;
    border: none;
  }



  .jMenuListItemsButton{
    text-align: left;
  width: 100%;
    display: block;
    padding:10px 30px;
    text-decoration: none;
    color: white;
    font-weight: 500;
    background: transparent;
    border:none;
    border-top: 1px solid white;
  }

  .openjHeadingMenuList1 {
    display: none;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    color: black;
    background-clip: padding-box;
    background-color: white;
  }
.openjHeadingMenuList1 a{
  color: white;
    background-color: #4778ad;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding-left: 60px;
    padding-right: 10px;
}

.openjHeadingMenuList1 a:hover{
  color: white;
    background-color: rgb(31,83,140);;
}
  .openjHeadingMenuList1hide{
    top:3rem;
  }

  .openjHeadingMenuList1show{
    top:10.8rem;
  }
  .openjHeadingMenuList1.open {
    display: block;
  }
  .openjHeadingMenuList1 ul{
    margin:0px 20px;
  }
  .openjHeadingMenuList1 ul li{
    list-style: none;
    padding: 10px;
    color: black;
  }
  .openjHeadingMenuList1 ul li:hover{
    /* text-decoration: underline; */
    cursor: pointer;
    color: #00A4B7;
  }


  .navigation {
 padding: 20px;
 width: 20%;
  }
  .pagenavigation{
    padding: 20px;
    width: 20%;
  }
  .paraSide{
    /* 80 */
    width:45%;
    padding: 30px 50px;
  }
  
  .articleMenuHeding{
    padding: 10px 10px;
    color: white;
    background-color: #00A4B7;
    font-weight: 600;
    font-size: 21px;
    text-align: center;
  }
  .articleMenu{
        border-top: none;
        border-left: none;
  }
  .articleSmallMenu{
    border: none;
  }
  .articleMenu a {
    display: block;
    padding: 5px 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: #4a4a4a;
    font-weight: 500;
    padding-left: 20px;
    border-left: 4px solid #305F8B;
  }
  .articleSmallMenu a {
    display: block;
    padding: 5px 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  .articleMenu a div{
    padding: 5px;
  }
  .articleSmallMenu a div{
    padding: 5px;
  }
  .articleMenu a:hover {
    /* background-color:#d8d2d2; */
    color: #00A4B7;
 }
 .articleSmallMenu a:hover {
  /* background-color:#d8d2d2; */
  color: #00A4B7;
}
 .articleContents:hover{
  color: #00A4B7;
 }
 .articleContents1:hover{
  color: #00A4B7;
 }
  
  .navigation div > a.active {
    background-color: #000;
    color: #fff;
    text-align: center;
  }
  .pagenavigation div > a.active {
    background-color: #000;
    color: #fff;
    text-align: center;
  }
  .section {
    z-index: 1;
    padding: 20px;
    scroll-margin-top: 50px;
  }

  .footer {
    height: 400px;
    padding: 15px;
    background-color: #eee;
  }

  

  .paraSideshow{
    text-align: justify;
  }
  .researchArticleLeft{
    width: 40%;
    border-right:1px solid #d5d5d5;
    padding-right: 20px;
  }
  .researchArticleRight{
    margin-left: 20px;
  }
  .paraSidehide{
    text-align: justify;
  }
  .paraSideshow h4{
    padding-top: 23px;
    scroll-margin-top: 200px;
  }
  .paraSidehide h4{
    padding-top: 23px;
    scroll-margin-top: 50px;
  }

  .citationButton{
    background: none;
    border: none;
    color:#00A4B7;
    cursor: pointer;
    font-size: 12px;
    padding: 0;
    /* margin:20px 0px; */
  }
  .citationButton:hover{
    text-decoration: underline;
  }
  .citationContent{
    padding: 12px 16px;
    background: #f5f5f5;
    margin:20px 0px;
  }

  .articleTile{
    font-size: 30px;
    font-weight: bold;
    font-family: STIXGeneral-Bold;
    line-height: 34px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
    margin-bottom: 12px;
  }

  .articleHeader__authors_author {
    font-size: 14px;
    margin-right: 4px;
    color: #000;
}

.simpleShowMore__content{
  margin-bottom: 12px;
}

.articleHeader__affiliations_affiliation:first-child {
  margin-top: 8px;
}

.articleHeader__affiliations_affiliation {
  margin-bottom: 12px;
}

.articleHeader__academicEditor {
  margin-bottom: 16px;
  color: #000;
}



.articleHeader__timeline {
  margin-top: 12px;
  border-bottom: 1px solid #e0e0e0;
  color: #000;
}




.rightSide{
  padding: 20px;
  width: 25%;
  /* position: fixed;
  top: 25%;
  right: 0; */
}
.downloadCont{
  padding: 20px;
  border-bottom: 1px solid white;
  background-color: #F5F5F5;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
}
.downloadCont:hover{
  background-color:#d8d2d2;
  cursor: pointer;
}
.downloadCont2{
  padding: 20px;
  border-bottom: 1px solid white;
  background-color: #F5F5F5;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
}
.downloadCont1{
  padding: 20px;
  border-bottom: 1px solid white;
  background-color: #00A4B7;
  color: white;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
}

.downloadCont1Small{
height: 3rem;
  background-color: #00A4B7;
  color: white;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  cursor: pointer;
}

.downloadCont1Small:hover{
  background-color: rgb(31,83,140);
}

.articleToolbarSocials{
  width: 25%;
  padding: 20px;
  border-right: 1px solid white;
  background-color: #F5F5F5;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
}

.articleToolbarSocials:hover{
  background-color: white;
  cursor: pointer;
}


.relatedArticleTitle{
  color: #4a4a4a;
    font-family: STIXGeneral-Bold,serif;
    line-height: 22px;
    font-weight: bold;
    font-size: 18px;
}

.relatedArticleAuthors{
  color: #848484;
      font-family: IBM Plex Sans,sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin: 3px 20px 0 0;
      opacity: 1;
}


table{
  border: none;
}
tbody tr:first-child{
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.showJump{
  display: none;
}
.showPagesMenuBars{
  display: none;
  cursor: pointer;
  color: white;
  width: 10%;
  background-color: rgba(31,83,140,1);
  height:4rem;
  padding: 20px 10px 5px 20px;
}
@media (min-width: 768px){
  .articleHeader__timeline {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #e0e0e0;
  }}
  
@media (min-width: 768px){
  .articleHeader__timeline_item:not(:last-child) {
      border-right: 1px solid #e0e0e0;
  }}
  
  @media (min-width: 768px){
  .articleHeader__timeline_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1;
      padding: 4px;
  }
 
}

@media (max-width: 992px) {
  .journalHeaderStyle{
    height: 3rem;
  }
  .jHeadingMenushow{
    top:7rem;
  }
  .jHeading{
    font-size: 18px;
  }
  .headerDiv {
    position: sticky;
  }

  .header {
    display: none;
  }

  .showArticleSction{
    cursor: pointer;
    position: sticky;
  background-color: #F2F2F2;
  color: #00A4B7;
  display: flex;
  border-top: 1px solid #fff;
  
  height:3rem;
  }
  .showArticleSctionshow{
    top:6.5rem;
  }
  .showArticleSctionhide{
    top:4rem;
  }
  .paraSide{
    width: 100%;
  }
  .researchArticleLeft{
    width: 50%;
  }
}

@media (max-width: 768px){
  .jHeading{
    font-size: 14px;
  }
  }
.showjHeadingMenuBars{
  display: flex;
    justify-content: center;
    align-items: center;
  cursor: pointer;
color: white;
background-color: rgba(31,83,140,1);
height:3rem;
}

.showjHeadingMenuBarsshow{
  top:7rem;
  display: block;
}
.showjHeadingMenuBarshide{
  top:0;
  display: none;
}
.pagesParaSide{
  width:80%;
  padding: 0px 30px;
}
@media (max-width: 992px) {
  .journalHeaderStyle{
    width:100%
  }
.showPagesMenuBars{
  display: contents;
}
.showJump{
  display: block;
}
.pagenavigation{
  display: none;
}
.pagesParaSide{
  width:100%
}
}

@media (max-width: 575px){
    .researchArticleLeft{
        width: 100%;
        border-right:none;
        padding-right: 0px;
      }
      .researchArticleRight{
        margin-left: 0px;
      }
      .paraSide {
        padding: 20px;
      }
    }